<template>
	<div class="row">
		<div class="col-12">
			<h2>{{ $t('monte.stock_semence.inventaire') }}</h2>
		</div>
		<div class="col-6 form-group">
			<label for="qte">{{ $t("monte.congelation.qte") }} *</label>
			<input class="form-control" type="number" v-model="qte" id="qte">
		</div>
		<div class="col-6 form-group">
			<label for="commentaire">{{ $t("monte.congelation.commentaire") }}</label>
			<input class="form-control" type="text" v-model="commentaire" id="commentaire">
		</div>
		<div class="col-12 text-center">
        	<b-button @click="submit" variant="primary" rounded-pill><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> <font-awesome-icon v-else :icon="['fal', 'save']" class="mr-1"/>{{ $t('global.sauvegarder') }}
        	</b-button>
        </div>
	</div>
</template>

<script type="text/javascript">
import Shutter from '@/mixins/Shutter'
import StockSemence from '@/mixins/StockSemence'

	export default {
		name: 'InventaireStock',
		mixins: [Shutter, StockSemence],
		props: ['horse', 'stock'],
		data () {
			return {
				qte: 0,
				commentaire: '',
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.qte = this.stock.dispo
			},

			async submit() {
				this.processing = true
				await this.addNewStockStallion(this.horse.horse_id, {
					lot: this.stock.lot,
					qte: this.qte - this.stock.dispo,
					couleur: this.stock.couleur ? this.stock.couleur.semencecouleur_code : '',
					localisation: this.stock.localisation_id,
					to: this.stock.to.tiers_id,
					commentaire: this.commentaire,
					parent: this.stock.uid
				})
				this.processing = false

				this.ok()
				this.shutterPanel().close('inventaire-stock')
			}
		}
	}

</script>